.gradient-custom-2 {
  background: linear-gradient(100deg, #2ca5d6, #32cd32);

  background: linear-gradient(100deg, #2ca5d6, #32cd32);

  background: linear-gradient(100deg, #2ca5d6, #32cd32);
  border: 1px solid transparent;
}

.sign_div {
  display: flex;
  justify-content: center;
  .sign_button {
    border: 1px solid transparent !important;
    height: 50px;
    width: 50%;
  }
}
.marg_div {
  margin-left: 3rem;
  @media (max-width: 567px) {
    margin-left: 0rem;
  }
}

.img_div {
  height: 100px;
  width: 100px;
  display: inline-block;

  color: #2ca5d6;
}

@media (max-width: 567px) {
  .row {
    flex-direction: column;
  }
}

@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}
